import { Navigate, Outlet } from 'react-router-dom'

interface GuardedRouteProps {
  /**
   * Permission check for route
   * @default false
   */
  isRouteAccessible?: boolean
  /**
   * Route to be redirected to
   * @default '/'
   */
  redirectRoute?: string
}

/**
 * Component for guarding restricted routes
 *
 * @example Default usage
 * ```ts
 * <GuardedRoute
 *	 isRouteAccessible={true}
 * />
 * ```
 *
 * @example Usage with custom redirected route
 * ```ts
 * <GuardedRoute
 *	 isRouteAccessible={false}
 *	 redirectRoute={'/login'}
 * />
 * ```
 */
const GuardedRoute = ({
  isRouteAccessible = false,
  redirectRoute = '/'
}: GuardedRouteProps): JSX.Element => {
  console.log(isRouteAccessible, redirectRoute)
  return isRouteAccessible ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  )
}

export default GuardedRoute
