import DB from '../types/Database'
import configs from '../atlasConfig.json'
import appConfig from '../appConfig.json'

export const saveRecord = async (
  record: DB.Record,
  mongoClient?: globalThis.Realm.Services.MongoDBDatabase,
  currentUser?: string
) => {
  const collection = mongoClient?.collection('asientos')
  return collection?.insertOne({
    ...record,
    createdBy: currentUser || '',
    createdAt: new Date(new Date().getTime() + 1000 * 60 * 60 * 6)
  })
}
export const deleteRecord = async (
  record: { _id: string } & DB.Record,
  undo: boolean,
  mongoClient?: globalThis.Realm.Services.MongoDBDatabase,
  currentUser?: string
) => {
  return undo
    ? mongoClient?.collection('asientos').deleteOne(record)
    : mongoClient?.collection('asientos').updateOne(
        { _id: record._id },
        {
          deleted: true,
          deletedBy: currentUser || '',
          deletedAt: new Date()
        }
      )
}

export const fetchData = async (
  query: DB.QueryObject,
  desde: Date = new Date(),
  hasta: Date = new Date(),
  mongoClient?: globalThis.Realm.Services.MongoDBDatabase
) => {
  const collection = mongoClient?.collection('asientos')
  for (const nivel of Object.keys(query)) {
    if (query[nivel as 'N0']?.length == 0) {
      delete query[nivel as 'N0']
    } else {
      if (query[nivel as 'N0']?.includes('cualquiera')) {
        delete query[nivel as 'N0']
      }
    }
  }
  if (Object.keys(query).length > 0) {
    return collection?.find({
      $and: [
        ...Object.keys(query).map(nivel => {
          return {
            $or: query[nivel as 'N0']?.map(value => {
              return { [nivel]: value }
            })
          }
        }),
        { fecha: { $gte: desde } },
        { fecha: { $lte: hasta } }
      ]
    })
  } else {
    return collection?.find({
      $and: [{ fecha: { $gte: desde } }, { fecha: { $lte: hasta } }]
    })
  }
}
