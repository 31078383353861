import {
  Box,
  Typography,
  TextField,
  Button,
  ThemeProvider,
  Container,
  CssBaseline,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  Snackbar,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import routes from "./routes";
import Wrapper from "../components/Wrapper";
import AppBar from "../components/AppBar";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import theme from "../theme";
import { useRecoilState } from "recoil";
import { opciones, origenFondos } from "../state";
import { saveRecord, deleteRecord } from "../db";
import { useState, Fragment } from "react";
import DB from "../types/Database";
import { useRealm } from "../context/RealmContext";

const Home = () => {
  const methods = useForm({
    defaultValues: {
      "N-1": "Banesco",
      N0: "egresos",
      N1: "",
      N2: "",
      N3: "",
      N4: "",
      N5: "",
      concepto: "",
      fecha: "",
      monto: ""
    }
  });
  const values = useWatch({ control: methods.control });
  console.log("🚀 ~ file: Home.tsx:47 ~ Home ~ values:", values);

  const [success, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const [undo, setUndo] = useState(false);
  const [lastWrite, setLastWrite] = useState({});
  const [dataOptions] = useRecoilState(opciones);
  const [origen] = useRecoilState(origenFondos);
  const { app, mongoClient } = useRealm();
  function handleSubmit(values: {
    "N-1": string;
    N0: string;
    N1: string | null;
    N2: string | null;
    N3: string | null;
    N4: string | null;
    N5: string | null;
    concepto: string;
    fecha: string;
    monto: string;
  }) {
    const dataToWrite = {
      ...values,
      fecha: new Date(new Date(values.fecha).getTime() + 1000 * 60 * 60 * 6),
      monto: Number(values.monto.replace(/,/, "."))
    };
    saveRecord(dataToWrite, mongoClient, app?.currentUser?.id)
      .then((result) => {
        console.log("🚀 ~ file: Home.tsx:70 ~ .then ~ result:", result);
        setSucces(true);
        setLastWrite(dataToWrite);
        methods.reset();
      })
      .catch((e: any) => {
        console.error(e);
        setError(true);
      });
  }
  function handleUndo() {
    deleteRecord(
      lastWrite as { _id: string } & DB.Record,
      true,
      mongoClient,
      app?.currentUser?.id
    )
      .then(() => {
        setUndo(true);
      })
      .catch((e: any) => {
        console.error(e);
        setError(true);
      });
  }
  function handleClose() {
    setSucces(false);
    setError(false);
    setUndo(false);
  }

  function handleGodModeChange(value: string) {
    const input = value;
    const checker = new RegExp("^[1-6][1,2][1-6]");
    if (checker.test(input)) {
      console.log("Paso el regex");
      const array = input.split("");
      const Nm1 = origen[Number(array.splice(0, 1)[0]) - 1];
      methods.setValue("N-1", Nm1);
      const N0 = array.splice(0, 1)[0] === "1" ? "ingresos" : "egresos";
      methods.setValue("N0", N0);
      const N1 =
        array.length > 0
          ? Object.keys(dataOptions[N0])[Number(array.splice(0, 1)[0]) - 1]
          : "";
      methods.setValue("N1", N1);
      const N2 =
        N1 === ""
          ? ""
          : dataOptions[N0][N1].opciones && array.length > 0
          ? dataOptions[N0][N1].opciones[
              Number(array.splice(0, 2).join("")) - 1
            ]
          : "";
      methods.setValue("N2", N2);
      const N3 =
        N2 === "" || N1 === ""
          ? ""
          : !(
              array.length > 0 &&
              Object.keys(dataOptions[N0][N1].nextLevel).length > 0
            )
          ? ""
          : dataOptions[N0][N1].nextLevel[N2 === "" ? "otros" : N2].opciones[
              Number(array.splice(0, 2).join("")) - 1
            ];
      methods.setValue("N3", N3);
      const N4 =
        N2 === "" || N1 === "" || N3 === ""
          ? ""
          : !(
              array.length > 0 &&
              Object.keys(
                dataOptions[N0][N1].nextLevel[N2 === "" ? "otros" : N2]
                  .nextLevel
              ).length > 0
            )
          ? ""
          : dataOptions[N0][N1].nextLevel[N2 === "" ? "otros" : N2].nextLevel[
              N3 === "" ? "otros" : N3
            ].opciones[Number(array.splice(0, 2).join("")) - 1];
      methods.setValue("N4", N4);
      console.log("🚀 ~ file: Home.tsx:101 ~ Valores Finales:", {
        N0,
        N1,
        N2,
        N3,
        N4
      });
    }
  }
  function handleSelectChange(deep: string, newValue: string) {
    const numberDeep = Number(deep.split("N")[1]);
    methods.setValue(`N${numberDeep}` as "N0", newValue);
    if (numberDeep >= 0) {
      for (let i = numberDeep + 1; i < 5; i++) {
        methods.setValue(`N${i}` as "N0", "");
      }
    }
  }
  const action = (
    <Fragment>
      <Button color="secondary" size="small" onClick={handleUndo}>
        Deshacer
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        title="Registro de Data"
        actions={
          <Grid>
            <Button
              sx={{ marginRight: "15px" }}
              color="primary"
              size="small"
              component={Link}
              to={routes.updateConfig}
              variant="contained"
            >
              Configura la applicación
            </Button>
            <Button
              sx={{ marginRight: "15px" }}
              color="primary"
              size="small"
              component={Link}
              to={routes.update}
              variant="contained"
            >
              Atualiza Asientos
            </Button>
            <Button
              color="primary"
              size="small"
              component={Link}
              to={routes.query}
              variant="contained"
            >
              Analiticas
            </Button>
          </Grid>
        }
      />
      <Wrapper maxWidth="lg">
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Typography variant="h4">Registra el asiento</Typography>
            <FormProvider {...methods}>
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                noValidate
              >
                <TextField
                  style={{ width: "100%", maxWidth: "500px" }}
                  margin="normal"
                  required
                  fullWidth
                  id="codigo"
                  label="Modo Maquina"
                  name="codigo"
                  autoFocus
                  onChange={(event) => {
                    event.preventDefault();
                    handleGodModeChange(event.target.value);
                  }}
                />
                <Typography variant="h6">
                  - O Introduce manualmente cada opcion deseada -
                </Typography>
                <Grid container direction="row" wrap="wrap">
                  <Grid item margin="1.5%">
                    <InputLabel id="tipo-0">Origen de fondos</InputLabel>
                    <Select
                      labelId="tipo-m1-select-label"
                      id="tipo-m1-select"
                      value={values["N-1"]}
                      label="Tipo"
                      onChange={(event) => {
                        event.preventDefault();
                        handleSelectChange("N-1", event.target.value);
                      }}
                    >
                      {origen.map((valor) => (
                        <MenuItem key={valor} value={valor}>
                          {valor}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item margin="1.5%">
                    <InputLabel id="tipo-0">Tipo de Entrada</InputLabel>
                    <Select
                      labelId="tipo-0-select-label"
                      id="tipo-0-select"
                      value={values.N0}
                      label="Tipo"
                      onChange={(event) => {
                        event.preventDefault();
                        handleSelectChange("N0", event.target.value);
                      }}
                    >
                      {Object.keys(dataOptions)
                        .sort((a: string, b: string) => {
                          return a == "egresos" ? -1 : 1;
                        })
                        .map((valor) => (
                          <MenuItem key={valor} value={valor}>
                            {valor}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item margin="1.5%">
                    <InputLabel id="tipo-1">Tipo de {values.N0}</InputLabel>
                    <Select
                      labelId="tipo-1-select-label"
                      id="tipo-1-select"
                      value={values.N1}
                      label={`Tipo de ${values.N0}`}
                      onChange={(event) => {
                        event.preventDefault();
                        handleSelectChange("N1", event.target.value);
                      }}
                    >
                      {/*
                // @ts-ignore */}
                      {Object.keys(dataOptions[values.N0]).map((valor) => (
                        <MenuItem key={valor} value={valor}>
                          {valor}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item margin="1.5%">
                    {values.N1 !== "" &&
                      dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                        ?.opciones?.length > 0 && (
                        <>
                          <InputLabel id="tipo-2">
                            Opcion de {values.N1}
                          </InputLabel>
                          <Select
                            labelId="tipo-2-select-label"
                            id="tipo-2-select"
                            value={values.N2}
                            label={`Opcion de ${values.N1}`}
                            onChange={(event) => {
                              event.preventDefault();
                              handleSelectChange("N2", event.target.value);
                            }}
                          >
                            {/*
                // @ts-ignore */}
                            {dataOptions[values.N0 as "ingresos"][
                              values.N1 || ""
                            ].opciones.map((valor) => (
                              <MenuItem key={valor} value={valor}>
                                {valor}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                  </Grid>
                  <Grid item margin="1.5%">
                    {values.N2 !== "" &&
                      (
                        dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                          .nextLevel[values.N2 || ""] ||
                        dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                          .nextLevel["otros"]
                      )?.opciones?.length > 0 && (
                        <>
                          <InputLabel id="tipo-3">
                            Opcion de {values.N2}
                          </InputLabel>
                          <Select
                            labelId="tipo-3-select-label"
                            id="tipo-3-select"
                            value={values.N3}
                            label={`Opcion de ${values.N2}`}
                            onChange={(event) => {
                              event.preventDefault();
                              handleSelectChange("N3", event.target.value);
                            }}
                          >
                            {/*
                // @ts-ignore */}
                            {(
                              dataOptions[values.N0 as "ingresos"][
                                values.N1 || ""
                              ].nextLevel[values.N2 || ""] ||
                              dataOptions[values.N0 as "ingresos"][
                                values.N1 || ""
                              ].nextLevel["otros"]
                            ).opciones.map((valor: any) => (
                              <MenuItem key={valor} value={valor}>
                                {valor}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                  </Grid>
                  <Grid item margin="1.5%">
                    {values.N3 !== "" &&
                      (
                        (
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel[values.N2 || ""] ||
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel["otros"]
                        )?.nextLevel[values.N3 || ""] ||
                        (
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel[values.N2 || ""] ||
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel["otros"]
                        ).nextLevel["otros"]
                      )?.opciones?.length > 0 && (
                        <>
                          <InputLabel id="tipo-4">
                            Opcion de {values.N3}
                          </InputLabel>
                          <Select
                            labelId="tipo-4-select-label"
                            id="tipo-4-select"
                            value={values.N4}
                            label={`Opcion de ${values.N3}`}
                            onChange={(event) => {
                              event.preventDefault();
                              handleSelectChange("N4", event.target.value);
                            }}
                          >
                            {/*
                // @ts-ignore */}
                            {(
                              (
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel[values.N2 || ""] ||
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel["otros"]
                              ).nextLevel[values.N3 || ""] ||
                              (
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel[values.N2 || ""] ||
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel["otros"]
                              ).nextLevel["otros"]
                            )?.opciones.map((valor: any) => (
                              <MenuItem key={valor} value={valor}>
                                {valor}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row" wrap="wrap">
                  <Grid item margin="1.5%" sm={2}>
                    <InputLabel id="tipo-0">Fecha de la entrada</InputLabel>
                    <TextField
                      type="date"
                      value={values.fecha}
                      onChange={(event) => {
                        event.preventDefault();
                        methods.setValue("fecha", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item margin="1.5%" sm={6}>
                    <InputLabel id="tipo-1">Descripción</InputLabel>
                    <TextField
                      sx={{ width: "100%" }}
                      type="text"
                      multiline
                      value={values.concepto}
                      maxRows={3}
                      onChange={(event) => {
                        event.preventDefault();
                        methods.setValue("concepto", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item margin="1.5%" sm={2}>
                    <InputLabel id="tipo-1">Monto</InputLabel>
                    <TextField
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      value={values.monto}
                      maxRows={3}
                      onChange={(event) => {
                        event.preventDefault();
                        methods.setValue("monto", event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Registrar
                </Button>
              </form>
            </FormProvider>
          </Box>
        </Container>
        <Snackbar
          open={success}
          autoHideDuration={5000}
          onClose={handleClose}
          message="Exito al registrar operación"
          action={action}
        />
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Ha ocurrido un error"
        />
        <Snackbar
          open={undo}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Deshecho correctamente"
        />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Home;
