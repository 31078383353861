/**
 * Define the different parts of your state here
 * using https://recoiljs.org/
 */
import { atom } from 'recoil'

export const sessionState = atom<any>({
  key: 'session',
  default: undefined
})

export const serviceWState = atom<any>({
  key: 'serviceW',
  default: { updateAvailable: false }
})

const proyectos: string[] = [
  'Decoples',
  'Venelux',
  'Premier',
  'Total Sabana Grande',
  'Polar 1',
  'Polar 2',
  'Guruceaga',
  'Torre 151',
  'Laser',
  'Apex',
  'La Tora',
  'Arquidiocesis',
  'Total La Trinidad',
  'Planta Stock'
]

export const origenFondos = atom<string[]>({
  key: 'fondos',
  default: ['Banesco', 'Facebank', 'caja1', 'caja2']
})

export const opciones = atom<{
  ingresos: Record<string, { opciones: string[]; nextLevel: any }>
  egresos: Record<string, { opciones: string[]; nextLevel: any }>
}>({
  key: 'opciones',
  default: {
    egresos: {
      Obras: {
        nextLevel: {
          otros: {
            nextLevel: {
              Aluminio: {
                nextLevel: {},
                opciones: ['Perfiles', 'Laminas Aluminio Coping']
              },
              Accesorios: {
                nextLevel: {},
                opciones: [
                  'Silicon',
                  'Cinta Tesa 3m praimer',
                  'Gomas',
                  'Tornillos Y KWIT-BOLTS',
                  'LAMINAS GALVANIZADAS',
                  'LANA MINERAL',
                  'FULMINANTES Y CLAVOS',
                  'INSERTOS',
                  'OTROS ACCESORIOS'
                ]
              }
            },
            opciones: [
              'Aluminio',
              'Pintura',
              'Vidrio',
              'Accesorios',
              'MDO INSTALACION'
            ]
          }
        },
        opciones: proyectos
      },
      'RRHH Oficina': {
        nextLevel: {},
        opciones: [
          'NOMINA OFICINA',
          'BONOS',
          'UTILIDADES',
          'VACACIONES',
          'LIQ. DE PRESTACIÓN DE ANTIGÜEDAD',
          'AYUDAS MEDICAS',
          'PRÉSTAMOS',
          'TRANSPORTE / GASOLINA',
          'REFRIGERIOS',
          'DOTACIONES',
          'OTROS GASTOS RRHH-OFICINA'
        ]
      },
      'RRHH PLanta': {
        nextLevel: {},
        opciones: [
          'NOMINA',
          'BONOS',
          'UTILIDADES',
          'VACACIONES',
          'LIQ. DE PRESTACIÓN DE ANTIGÜEDAD',
          'AYUDAS MEDICAS',
          'PRÉSTAMOS',
          'TRANSPORTE / GASOLINA',
          'REFRIGERIOS',
          'DOTACIONES',
          'OTROS GASTOS RRHH-PLANTA'
        ]
      },
      'Labor Social': {
        nextLevel: {
          'Fundación Comuna': {
            nextLevel: {
              Talleres: {
                nextLevel: {},
                opciones: ['Desayunos', 'Refrigerios', 'Transporte']
              },
              Eventos: {
                nextLevel: {},
                opciones: ['Refrigerios', 'Transporte']
              }
            },
            opciones: [
              'Vigilantes',
              'Becas',
              'Talleres',
              'Ayudas Medicas',
              'Eventos',
              'Donaciones',
              'Prestamos',
              'Otros Gastos Fundacion Comuna'
            ]
          }
        },
        opciones: ['Donaciones QG', 'Fundación Comuna']
      },
      Accionistas: {
        nextLevel: {
          'SERVICIOS ACCIONISTAS': {
            nextLevel: {},
            opciones: [
              'Condominios',
              'Telefonos',
              'Clubes',
              'Electricidad',
              'Aseo',
              'Otros Servicios'
            ]
          }
        },
        opciones: [
          'VIATICOS',
          'PAGOS PRESTAMOS ACCIONISTAS',
          'SERVICIOS ACCIONISTAS',
          'OTROS GASTOS ACCIONISTAS'
        ]
      },
      'Gastos Generales': {
        nextLevel: {
          IMPUESTOS: {
            nextLevel: {},
            opciones: ['ISLR', 'IVA', 'IGTF', 'ALCALDIA']
          },
          'COMISIONES BANCARIAS': {
            nextLevel: {},
            opciones: ['Locales', 'Internacionales']
          },
          'SERVICIOS BASICOS': {
            nextLevel: {},
            opciones: [
              'Electricidad',
              'Agua',
              'Telefono',
              'Internet',
              'Otros Servicios Basicos'
            ]
          },
          'SERVICIOS ADMINISTRATIVOS': {
            nextLevel: {},
            opciones: [
              'IVSS',
              'FAOV',
              'LOGÍSTICA / NACIONALIZACIÓN',
              'MARKETING',
              'TRÁMITES VARIOS'
            ]
          },
          VEHICULOS: {
            nextLevel: {
              Combustible: {
                nextLevel: {},
                opciones: ['Gasolina', 'Gasoil']
              }
            },
            opciones: [
              'Adquisicion',
              'Mantenimiento',
              'Combustible',
              'Repuestos',
              'Otros Gastos Vehiculo'
            ]
          },
          Tecnologia: {
            nextLevel: {},
            opciones: [
              'Desarrollo Tecnologico',
              'Compra Equipos',
              'Otros Gastos Tecnologia'
            ]
          },
          'GASTOS DE PLANTA': {
            nextLevel: {
              'Maquinaria Pesada': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              },
              'Maquinaria Ligera': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              },
              'Equipos Y Herramientas': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              },
              'Arreglos de Infraestructura': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              }
            },
            opciones: [
              'Maquinaria Pesada',
              'Maquinaria Ligera',
              'Equipos Y Herramientas',
              'Arreglos de Infraestructura',
              'Otros Gastos de Planta'
            ]
          },
          'GASTOS DE OBRA': {
            nextLevel: {
              'Maquinaria Ligera': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              },
              'Equipos Y Herramientas': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              }
            },
            opciones: [
              'Maquinaria Ligera',
              'Equipos Y Herramientas',
              'Otros Gastos de Obra'
            ]
          },
          'GASTOS DE OFICINA': {
            nextLevel: {
              EQUIPOS: {
                nextLevel: {
                  COMPUTADORAS: {
                    nextLevel: {},
                    opciones: ['Adquisicion', 'Reparacion']
                  },
                  'CAMARAS Y SEGURIDAD': {
                    nextLevel: {},
                    opciones: ['Adquisicion', 'Reparacion']
                  },
                  'OTROS EQUIPOS': {
                    nextLevel: {},
                    opciones: ['Adquisicion', 'Reparacion']
                  }
                },
                opciones: [
                  'COMPUTADORAS',
                  'CAMARAS Y SEGURIDAD',
                  'OTROS EQUIPOS'
                ]
              },
              'Equipos Y Herramientas': {
                nextLevel: {},
                opciones: ['Adquisicion', 'Reparacion']
              },
              INSUMOS: {
                opciones: ['Agua', 'Insumos para Cafe', 'Limpieza']
              }
            },
            opciones: [
              'PAPELERIA E INSUMOS',
              'EQUIPOS',
              'INSUMOS',
              'OTROS GASTOS DE OFICINA'
            ]
          }
        },
        opciones: [
          'IMPUESTOS',
          'COMISIONES BANCARIAS',
          'SERVICIOS BASICOS',
          'VIGILANCIA',
          'SERVICIOS ADMINISTRATIVOS',
          'VEHICULOS',
          'TECNOLOGIA',
          'GASTOS DE PLANTA',
          'GASTOS DE OBRA',
          'GASTOS DE OFICINA',
          'OTROS GASTOS GENERALES'
        ]
      }
    },
    ingresos: {
      'Cobranza Por Proyecto': {
        nextLevel: {},
        opciones: proyectos
      },
      'Servicios Post-Venta': { nextLevel: {}, opciones: [] },
      'Aporte de Accionistas': { nextLevel: {}, opciones: [] },
      Reversos: { nextLevel: {}, opciones: [] },
      'Intereses Bancarios': { nextLevel: {}, opciones: [] },
      Impuestos: { nextLevel: {}, opciones: [] },
      'Otros ingresos': { nextLevel: {}, opciones: [] }
    }
  }
})

export const queryoptions = atom<{
  N1: string[]
  N2: string[]
  N3: string[]
}>({
  key: 'queryOptions',
  default: {
    N1: [
      'Cobranza Por Proyecto',
      'Servicios Post-Venta',
      'Aporte de Accionistas',
      'Reversos',
      'Intereses Bancarios',
      'Otros ingresos',
      'Obras',
      'RRHH Oficina',
      'RRHH PLanta',
      'Labor Social',
      'Accionistas',
      'Gastos Generales'
    ],
    N2: [
      ...proyectos,
      'NOMINA OFICINA',
      'BONOS',
      'UTILIDADES',
      'VACACIONES',
      'LIQ. DE PRESTACIÓN DE ANTIGÜEDAD',
      'AYUDAS MEDICAS',
      'PRÉSTAMOS',
      'TRANSPORTE / GASOLINA',
      'REFRIGERIOS',
      'DOTACIONES',
      'OTROS GASTOS RRHH-OFICINA',
      'OTROS GASTOS RRHH-PLANTA',
      'Donaciones QG',
      'Fundación Comuna',
      'VIATICOS',
      'PAGOS PRESTAMOS ACCIONISTAS',
      'SERVICIOS ACCIONISTAS',
      'OTROS GASTOS ACCIONISTAS',
      'IMPUESTOS',
      'COMISIONES BANCARIAS',
      'SERVICIOS BASICOS',
      'VIGILANCIA',
      'SERVICIOS ADMINISTRATIVOS',
      'VEHICULOS',
      'TECNOLOGIA',
      'GASTOS DE PLANTA',
      'GASTOS DE OBRA',
      'GASTOS DE OFICINA',
      'OTROS GASTOS GENERALES'
    ],
    N3: [
      'Aluminio',
      'Pintura',
      'Vidrio',
      'Accesorios',
      'MDO INSTALACION',
      'Vigilantes',
      'Becas',
      'Talleres',
      'Ayudas Medicas',
      'Eventos',
      'Donaciones',
      'Prestamos',
      'Otros Gastos Fundacion Comuna',
      'Condominios',
      'Telefonos',
      'Clubes',
      'Electricidad',
      'Aseo',
      'Otros Servicios',
      'ISLR',
      'IVA',
      'IGTF',
      'ALCALDIA',
      'Locales',
      'Internacionales',
      'Electricidad',
      'Agua',
      'Telefono',
      'Internet',
      'Otros Servicios Basicos',
      'IVSS',
      'FAOV',
      'LOGÍSTICA / NACIONALIZACIÓN',
      'MARKETING',
      'TRÁMITES VARIOS',
      'Adquisicion',
      'Mantenimiento',
      'Combustible',
      'Repuestos',
      'Otros Gastos Vehiculo',
      'Desarrollo Tecnologico',
      'Compra Equipos',
      'Otros Gastos Tecnologia',
      'Maquinaria Pesada',
      'Maquinaria Ligera',
      'Equipos Y Herramientas',
      'Arreglos de Infraestructura',
      'Otros Gastos de Planta',
      'Otros Gastos de Obra',
      'PAPELERIA E INSUMOS',
      'EQUIPOS',
      'INSUMOS',
      'OTROS GASTOS DE OFICINA'
    ]
  }
})
