import {
  Button,
  ThemeProvider,
  Container,
  CssBaseline,
  Snackbar,
  Dialog,
  DialogTitle,
  Alert
} from '@mui/material'
import routes from './routes'
import Wrapper from '../components/Wrapper'
import AppBar from '../components/AppBar'
import theme from '../theme'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import DropZone from '../components/DropZone'
import { utils, read } from 'xlsx'
import { useRealm } from '../context/RealmContext'

export default () => {
  const [noti, setNoti] = useState({ open: false, error: false })
  const [data, setData] = useState<Record<string, any>[]>([])
  const [loading, setLoading] = useState(false)
  const { mongoFunctions } = useRealm()
  function handleClose() {
    setNoti({ open: false, error: false })
  }
  function convertToJson(headers: string[], data: Record<string, any>[]) {
    const rows: Record<string, any>[] = []
    data.forEach(row => {
      const rowData: Record<string, any> = {}
      row.forEach((element: Record<string, any>, index: number) => {
        rowData[String(headers[index]).toLowerCase()] = element
      })
      rows.push(rowData)
    })
    setData(rows)
    return rows
  }
  function importExcel(files: Blob[]) {
    const file = files[0]
    const reader = new FileReader()
    reader.onload = evento => {
      const workBook = read(evento?.target?.result, { type: 'binary' })
      const workSheet = workBook.Sheets[workBook.SheetNames[0]]
      const fileData = utils.sheet_to_json(workSheet, { header: 1 })
      const headers = fileData[0] as string[]
      fileData.splice(0, 1)
      convertToJson(headers, fileData as Record<string, any>[])
    }
    reader.readAsBinaryString(file)
  }

  function saveToDB() {
    setLoading(true)
    if (data.length === 0) {
      Alert({ title: 'No puede subir la data vacia', severity: 'warning' })
    }
    if (mongoFunctions) {
      return mongoFunctions
        .updateAsientos(data)
        .then(() => {
          setData([])
          setNoti({ open: true, error: false })
          setLoading(false)
        })
        .catch(e => {
          console.error(e)
          setData([])
          setNoti({ open: true, error: true })
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (data.length > 0) {
      saveToDB()
    }
  }, [data])

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        title='Actualiza tu Data'
        actions={
          <Button
            color='primary'
            size='small'
            component={Link}
            to={routes.home}
            variant='contained'
          >
            Registro
          </Button>
        }
      />
      <Wrapper maxWidth='lg'>
        <Container component='main' maxWidth='lg'>
          <CssBaseline />
          <DropZone onChange={importExcel} />
        </Container>
        <Dialog open={loading}>
          <DialogTitle>Cargando...</DialogTitle>
        </Dialog>
        <Snackbar
          open={noti.open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={
            noti.error
              ? 'Ha ocurrido un error actualizando los asientos'
              : 'Exito, ya actualizamos los asientos'
          }
        />
      </Wrapper>
    </ThemeProvider>
  )
}
