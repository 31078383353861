import { Route, Routes, Navigate } from 'react-router'
import Signin from './Signin'
import Home from './Home'
import Query from './Query'
import UpdateConfig from './updateConfig'
import routes from './routes'
import GuardedRoute from '../routes/guardedRoute'
import { useRealm } from '../context/RealmContext'
import { useServiceWorker } from '../hooks/sw'
import { Alert, Button } from '@mui/material'
import Update from './Update'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { opciones, origenFondos, queryoptions } from '../state'

const App = () => {
  const { app, mongoClient } = useRealm()
  const { waitingWorker, showReload, reloadPage } = useServiceWorker()
  const setOpciones = useSetRecoilState(opciones)
  const setOrigenFondos = useSetRecoilState(origenFondos)
  const setQueryOptions = useSetRecoilState(queryoptions)
  useEffect(() => {
    console.log('Pidiendo la config del server')
    mongoClient
      ?.collection('config')
      .findOne({ _id: 'general' })
      .then(config => {
        const ingresos = config.ingresos
        const egresos = config.egresos
        const proyectos = config.proyectos
        const N1i = Object.keys(ingresos)
        const N1e = Object.keys(egresos)
        const N2i = proyectos
        const N2e = new Set(
          N1e.map(key => {
            return egresos[key].opciones
          }).flat()
        )
        ingresos['Cobranza Por Proyecto'].opciones = proyectos
        egresos['Obras'].opciones = proyectos
        setOpciones(() => {
          return { ingresos, egresos }
        })
        setOrigenFondos(() => config.origenFondos)
        setQueryOptions(valorActual => {
          return {
            ...valorActual,
            N1: [...N1e, ...N1i],
            N2: [...N2i, ...Array.from(N2e)]
          }
        })
      })
      .catch(e => {
        console.error(e)
      })
  }, [])
  return (
    <>
      <Routes>
        <Route path={routes.signin} element={<Signin />} />
        <Route
          element={
            <GuardedRoute
              isRouteAccessible={!!app?.currentUser}
              redirectRoute={routes.signin}
            />
          }
        >
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.query} element={<Query />} />
          <Route path={routes.update} element={<Update />} />
          <Route path={routes.updateConfig} element={<UpdateConfig />} />
          <Route path='*' element={<Navigate to={routes.home} />} />
        </Route>
      </Routes>
      {showReload && waitingWorker && (
        <Alert
          title='Actualiza macutrol, porfavor.'
          action={
            <Button color='inherit' size='small' onClick={reloadPage}>
              Actualizar
            </Button>
          }
          severity='info'
        >
          Actualiza macutrol, porfavor.
        </Alert>
      )}
    </>
  )
}

export default App
