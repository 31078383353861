import { Autocomplete, TextField } from '@mui/material'

export function Select(props: any) {
  const { data, setData, setValue, value, freeSolo, ...other } = props
  const handleSelectionChange = async (event: any, value1: string[]) => {
    event.preventDefault()
    if (typeof setData === 'function') {
      setData(value1)
    }
    setValue(value1)
  }

  return (
    <Autocomplete
      multiple
      options={data}
      freeSolo={freeSolo}
      onChange={handleSelectionChange}
      value={value}
      renderInput={params => (
        <TextField
          {...other}
          {...params}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>
          }}
        />
      )}
    />
  )
}

export default Select
