import {
  Box,
  Typography,
  Button,
  ThemeProvider,
  Container,
  CssBaseline,
  InputLabel,
  Grid,
  Divider,
  Snackbar,
  IconButton
} from '@mui/material'
import { writeFile, utils } from 'xlsx'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import routes from './routes'
import Wrapper from '../components/Wrapper'
import AppBar from '../components/AppBar'
import AutoSelect from '../components/AutoSelect'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import theme from '../theme'
import { useRecoilState } from 'recoil'
import { origenFondos, queryoptions } from '../state'
import { fetchData } from '../db'
import { useState, Fragment } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useRealm } from '../context/RealmContext'

const Home = () => {
  const methods = useForm({
    defaultValues: {
      'N-1': [] as string[],
      N0: [] as string[],
      N1: [] as string[],
      N2: [] as string[],
      N3: [] as string[],
      N4: [] as string[],
      N5: [] as string[],
      desde: '',
      hasta: ''
    }
  })
  const values = useWatch({ control: methods.control })
  const [success, setSucces] = useState(false)
  const [error, setError] = useState(false)
  const [undo, setUndo] = useState(false)
  const [queryOptions] = useRecoilState(queryoptions)
  const [origen] = useRecoilState(origenFondos)
  const { app, mongoClient } = useRealm()
  function handleSubmit(values: {
    'N-1': string[]
    N0: string[]
    N1: string[]
    N2: string[]
    N3: string[]
    N4: string[]
    N5: string[]
    desde: unknown
    hasta: unknown
  }) {
    const desde = new Date(values.desde as string)
    const hasta = new Date(
      new Date(values.hasta as string).setHours(23, 59, 59)
    )
    delete values.desde
    delete values.hasta
    fetchData(values, desde, hasta, mongoClient).then(data => {
      if (data) {
        const excelData = data.map((asiento: any) => {
          const debe = asiento['N0'] === 'ingresos' ? '' : Number(asiento.monto)
          const haber =
            asiento['N0'] === 'ingresos' ? Number(asiento.monto) : ''
          return {
            id: asiento._id.toString(),
            N0: asiento['N-1'],
            N1: asiento.N1,
            N2: asiento.N2,
            N3: asiento.N3,
            N4: asiento.N4,
            N5: asiento.N5,
            debe,
            haber,
            concepto: asiento.concepto,
            fechaAsiento: new Date(asiento.fecha),
            fechaRegistro: new Date(asiento.createdAt)
          }
        })
        const hoja = utils.json_to_sheet(excelData, {
          header: [
            'fechaAsiento',
            'debe',
            'haber',
            'N0',
            'N1',
            'N2',
            'N3',
            'N4',
            'N5',
            'concepto',
            'fechaRegistro',
            'id'
          ]
        })
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, hoja, 'DataCruda')
        writeFile(workbook, 'Macutrol.xlsx')
      }
    })
  }
  function handleClose() {
    setSucces(false)
    setError(false)
    setUndo(false)
  }
  function handleSelectChange(deep: string, newValue: string[]) {
    const numberDeep = Number(deep.split('N')[1])
    methods.setValue(`N${numberDeep}` as 'N0', newValue)
  }
  const action = (
    <Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </Fragment>
  )

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        title='Pide tu Data'
        actions={
          <Button
            color='primary'
            size='small'
            component={Link}
            to={routes.home}
            variant='contained'
          >
            Registro
          </Button>
        }
      />
      <Wrapper maxWidth='lg'>
        <Container component='main' maxWidth='lg'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant='h4'>Filtra Lo que necesites</Typography>
            <FormProvider {...methods}>
              <form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                noValidate
              >
                <Grid container direction='row' wrap='wrap' alignItems='center'>
                  <Grid item xs={5}>
                    <DatePicker
                      disableFuture
                      label='Desde'
                      value={values['desde' as 'N0']}
                      onChange={(newValue: unknown) => {
                        return methods.setValue(
                          `desde` as 'desde',
                          newValue as string
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent='center'>
                    -
                  </Grid>
                  <Grid item xs={5}>
                    <DatePicker
                      disableFuture
                      label='Hasta'
                      value={values['hasta' as 'N0']}
                      onChange={(newValue: unknown) => {
                        if (newValue !== null) {
                          return methods.setValue(
                            `hasta` as 'hasta',
                            newValue as string
                          )
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography variant='h6'>
                  - Introduce Lo que deseas obtener -
                </Typography>
                <Grid container direction='row' wrap='wrap'>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-m1'>Origen de fondos</InputLabel>
                    <AutoSelect
                      multiple={true}
                      data={[...origen, 'cualquiera']}
                      value={values['N-1']}
                      setValue={(value: any) => {
                        handleSelectChange('N-1', value)
                      }}
                    />
                  </Grid>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-0'>Tipo de Entrada</InputLabel>
                    <AutoSelect
                      multiple={true}
                      data={['ingresos', 'egresos']}
                      value={values['N0']}
                      setValue={(value: any) => {
                        handleSelectChange('N0', value)
                      }}
                    />
                  </Grid>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-1'>Tipo de Movimiento</InputLabel>
                    <AutoSelect
                      multiple={true}
                      data={[...queryOptions['N1'], 'cualquiera']}
                      value={values['N1']}
                      setValue={(value: any) => {
                        handleSelectChange('N1', value)
                      }}
                    />
                  </Grid>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-2'>Tipo de Movimiento 2</InputLabel>
                    <AutoSelect
                      multiple={true}
                      data={[...queryOptions['N2'], 'cualquiera']}
                      value={values['N2']}
                      setValue={(value: any) => {
                        handleSelectChange('N2', value)
                      }}
                    />
                  </Grid>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-3'>Tipo de Movimiento 3</InputLabel>
                    <AutoSelect
                      multiple={true}
                      data={[...queryOptions['N3'], 'cualquiera']}
                      value={values['N3']}
                      setValue={(value: any) => {
                        handleSelectChange('N3', value)
                      }}
                    />
                  </Grid>
                  {/*
                  <Grid item margin="1.5%">
                    {values.N1 !== "any" &&
                      dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                        ?.opciones?.length > 0 && (
                        <>
                          <InputLabel id="tipo-2">
                            Opcion de {values.N1}
                          </InputLabel>
                          <Select
                            labelId="tipo-2-select-label"
                            id="tipo-2-select"
                            value={values.N2}
                            label={`Opcion de ${values.N1}`}
                            onChange={(event) => {
                              event.preventDefault();
                              handleSelectChange("N2", event.target.value);
                            }}
                          >

                            {dataOptions[values.N0 as "ingresos"][
                              values.N1 || ""
                            ].opciones
                              .map((valor) => (
                                <MenuItem key={valor} value={valor}>
                                  {valor}
                                </MenuItem>
                              ))
                              .push(
                                <MenuItem key={"any"} value={"any"}>
                                  {"any"}
                                </MenuItem>
                              )}
                          </Select>
                        </>
                      )}
                  </Grid>
                  <Grid item margin="1.5%">
                    {values.N2 !== "any" &&
                      (
                        dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                          .nextLevel[values.N2 || ""] ||
                        dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                          .nextLevel["otros"]
                      )?.opciones?.length > 0 && (
                        <>
                          <InputLabel id="tipo-3">
                            Opcion de {values.N2}
                          </InputLabel>
                          <Select
                            labelId="tipo-3-select-label"
                            id="tipo-3-select"
                            value={values.N3}
                            label={`Opcion de ${values.N2}`}
                            onChange={(event) => {
                              event.preventDefault();
                              handleSelectChange("N3", event.target.value);
                            }}
                          >
                            {(
                              dataOptions[values.N0 as "ingresos"][
                                values.N1 || ""
                              ].nextLevel[values.N2 || ""] ||
                              dataOptions[values.N0 as "ingresos"][
                                values.N1 || ""
                              ].nextLevel["otros"]
                            ).opciones
                              .map((valor: any) => (
                                <MenuItem key={valor} value={valor}>
                                  {valor}
                                </MenuItem>
                              ))
                              .push(
                                <MenuItem key={"any"} value={"any"}>
                                  {"any"}
                                </MenuItem>
                              )}
                          </Select>
                        </>
                      )}
                  </Grid>
                  <Grid item margin="1.5%">
                    {values.N3 !== "any" &&
                      (
                        (
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel[values.N2 || ""] ||
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel["otros"]
                        ).nextLevel[values.N3 || ""] ||
                        (
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel[values.N2 || ""] ||
                          dataOptions[values.N0 as "ingresos"][values.N1 || ""]
                            .nextLevel["otros"]
                        ).nextLevel["otros"]
                      )?.opciones?.length > 0 && (
                        <>
                          <InputLabel id="tipo-4">
                            Opcion de {values.N3}
                          </InputLabel>
                          <Select
                            labelId="tipo-4-select-label"
                            id="tipo-4-select"
                            value={values.N4}
                            label={`Opcion de ${values.N3}`}
                            onChange={(event) => {
                              event.preventDefault();
                              handleSelectChange("N4", event.target.value);
                            }}
                          >
                            {(
                              (
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel[values.N2 || ""] ||
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel["otros"]
                              ).nextLevel[values.N3 || ""] ||
                              (
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel[values.N2 || ""] ||
                                dataOptions[values.N0 as "ingresos"][
                                  values.N1 || ""
                                ].nextLevel["otros"]
                              ).nextLevel["otros"]
                            )?.opciones
                              .map((valor: any) => (
                                <MenuItem key={valor} value={valor}>
                                  {valor}
                                </MenuItem>
                              ))
                              .push(
                                <MenuItem key={"any"} value={"any"}>
                                  {"any"}
                                </MenuItem>
                              )}
                          </Select>
                        </>
                      )}
                  </Grid>
                   */}
                </Grid>
                <Divider />

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Generar XLS
                </Button>
              </form>
            </FormProvider>
          </Box>
        </Container>
        <Snackbar
          open={success}
          autoHideDuration={5000}
          onClose={handleClose}
          message='Exito, ya descargaras tu archivo'
          action={action}
        />
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={handleClose}
          message='Ha ocurrido un error'
        />
      </Wrapper>
    </ThemeProvider>
  )
}

export default Home
