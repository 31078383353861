import { createContext, useContext } from 'react'
import * as Realm from 'realm-web'
import atlasConfig from '../atlasConfig.json'
import variables from '../appConfig.json'
const { appId } = atlasConfig[variables.environment as 'PROD']

type mongoContext = {
  app?: Realm.App
  mongoClient?: globalThis.Realm.Services.MongoDBDatabase
  mongoFunctions?: globalThis.Realm.DefaultFunctionsFactory &
    globalThis.Realm.BaseFunctionsFactory
}

const RealmContext = createContext<mongoContext>({})

export function RealmProvider({ children }: { children: any }) {
  const app = Realm.getApp(appId)
  let mongoClient
  let mongoFunctions
  if (app && app.currentUser) {
    mongoClient = app.currentUser
      .mongoClient('mongodb-atlas')
      .db(variables.environment === 'STAGE' ? 'staging' : 'production')
    mongoFunctions = app.currentUser.functions
  }

  return (
    <RealmContext.Provider value={{ app, mongoClient, mongoFunctions }}>
      {children}
    </RealmContext.Provider>
  )
}

export function useRealm() {
  return useContext(RealmContext)
}
