import { createRoot } from 'react-dom/client'
import App from './pages/App'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import ErrorBoundary from './components/ErrorBoundary'
import Error from './pages/Error'
import { RecoilRoot } from 'recoil'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { RealmProvider } from './context/RealmContext'

// material ui theme
import theme from './theme'
// fonts - material ui was built with roboto in mind
import 'typeface-roboto'

// some global css
import './index.css'
const root = createRoot(document.getElementById('root')!)

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RealmProvider>
      <BrowserRouter>
        <RecoilRoot>
          <ErrorBoundary onError={console.log} errorComponent={<Error />}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </ErrorBoundary>
        </RecoilRoot>
      </BrowserRouter>
    </RealmProvider>
  </ThemeProvider>
)
