import {
  Box,
  Typography,
  Button,
  ThemeProvider,
  Container,
  CssBaseline,
  InputLabel,
  Grid,
  Divider,
  Snackbar,
  IconButton
} from '@mui/material'
import { writeFile, utils } from 'xlsx'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import routes from './routes'
import Wrapper from '../components/Wrapper'
import AppBar from '../components/AppBar'
import AutoSelect from '../components/AutoSelect'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import theme from '../theme'
import { useRecoilState } from 'recoil'
import { origenFondos, queryoptions, opciones } from '../state'
import { useState, Fragment, useEffect } from 'react'
import { useRealm } from '../context/RealmContext'
const UpdateConfig = () => {
  const [queryOptions, setQueryOptions] = useRecoilState(queryoptions)
  const [origen, setOrigenFondos] = useRecoilState(origenFondos)
  const [inegre, setOpciones] = useRecoilState(opciones)
  const { app, mongoClient } = useRealm()
  const methods = useForm({
    defaultValues: {
      proyectos: (inegre?.egresos?.Obras?.opciones || []) as string[],
      origenFondos: origen as string[]
    }
  })
  const values = useWatch({ control: methods.control })
  const [success, setSucces] = useState(false)
  const [error, setError] = useState(false)
  function handleSubmit(values: {
    proyectos: string[]
    origenFondos: string[]
  }) {
    return mongoClient
      ?.collection('config')
      .findOneAndUpdate(
        { _id: 'general' },
        { $set: { ingresos: inegre.ingresos, egresos: inegre.egresos } }
      )
      .then(() => {
        setError(false)
        setSucces(true)
      })
      .catch(e => {
        setError(true)
        setSucces(false)
      })
  }
  function handleClose() {
    setSucces(false)
    setError(false)
  }
  const action = (
    <Fragment>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </Fragment>
  )
  function handleSelectChange(opcion: string, value: string[]) {
    mongoClient
      ?.collection('config')
      .findOneAndUpdate({ _id: 'general' }, { $set: { [opcion]: value } })
      .then(() => {
        setError(false)
        setSucces(true)
      })
      .catch(e => {
        setError(true)
        setSucces(false)
      })
  }
  useEffect(() => {
    console.log(origen, inegre)
    methods.setValue('origenFondos', origen)
    methods.setValue('proyectos', inegre?.egresos?.Obras?.opciones || [])
  }, [origen, inegre])

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        title='Configuraciones'
        actions={
          <Button
            color='primary'
            size='small'
            component={Link}
            to={routes.home}
            variant='contained'
          >
            Registro
          </Button>
        }
      />
      <Wrapper maxWidth='lg'>
        <Container component='main' maxWidth='lg'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant='h4'>Configura tu app</Typography>
            <FormProvider {...methods}>
              <form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                noValidate
              >
                <Grid container direction='row' wrap='wrap'>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-m1'>Origen de fondos</InputLabel>
                    <AutoSelect
                      multiple={true}
                      freeSolo={true}
                      data={[...origen]}
                      value={values['origenFondos']}
                      setValue={(value: any) => {
                        return handleSelectChange('origenFondos', value)
                      }}
                      setData={(data: string[]) => {
                        values['origenFondos'] = data
                        return setOrigenFondos(() => data)
                      }}
                    />
                  </Grid>
                  <Grid item margin='1.5%'>
                    <InputLabel id='tipo-0'>Proyectos</InputLabel>
                    <AutoSelect
                      multiple={true}
                      freeSolo={true}
                      data={inegre?.egresos?.Obras?.opciones || []}
                      value={values['proyectos']}
                      setValue={(value: any) => {
                        return handleSelectChange('proyectos', value)
                      }}
                      setData={(data: string[]) => {
                        const { ingresos, egresos } = inegre
                        const N1i = Object.keys(ingresos)
                        const N1e = Object.keys(egresos)
                        const N2i = data
                        const N2e = new Set(
                          N1e.map(key => {
                            return egresos[key].opciones
                          }).flat()
                        )
                        setQueryOptions(valorActual => {
                          return {
                            ...valorActual,
                            N1: [...N1e, ...N1i],
                            N2: [...N2i, ...Array.from(N2e)]
                          }
                        })
                        return setOpciones(actual => {
                          const { ingresos, egresos } = actual
                          values['proyectos'] = data
                          return {
                            ingresos: {
                              ...ingresos,
                              ['Cobranza Por Proyecto']: {
                                ...ingresos['Cobranza Por Proyecto'],
                                opciones: data
                              }
                            },
                            egresos: {
                              ...egresos,
                              Obras: {
                                ...egresos.Obras,
                                opciones: data
                              }
                            }
                          }
                        })
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction='row' wrap='wrap'>
                  {Object.keys(inegre).map(tipo => {
                    return (
                      <Grid item margin='1.5%'>
                        <InputLabel id='tipo-m1'>Tipo de {tipo}</InputLabel>
                        <AutoSelect
                          multiple={true}
                          freeSolo={true}
                          data={Object.keys(inegre[tipo as 'ingresos'])}
                          value={Object.keys(inegre[tipo as 'ingresos'])}
                          setValue={(value: string[]) => {
                            const deleted =
                              value.length <
                              Object.keys(inegre[tipo as 'ingresos']).length
                            const change = deleted
                              ? Object.keys(inegre[tipo as 'ingresos']).find(
                                  tipo => {
                                    return !value.includes(tipo)
                                  }
                                )
                              : value.find(tipo1 => {
                                  return !Object.keys(
                                    inegre[tipo as 'ingresos']
                                  ).includes(tipo1)
                                })
                            setOpciones(current => {
                              const cambio = {
                                ...current[tipo as 'ingresos']
                              }
                              if (deleted) {
                                delete cambio[change as string]
                              } else {
                                cambio[change as string] = {
                                  nextLevel: {},
                                  opciones: []
                                }
                              }
                              return {
                                ...current,
                                [tipo as 'ingresos']: cambio
                              }
                            })
                          }}
                        />

                        <Divider
                          sx={{
                            color: 'black',
                            marginTop: '20px',
                            marginBottom: '-20px'
                          }}
                        >
                          Opciones del Tipo de {tipo}
                        </Divider>
                        <Typography variant='h5' sx={{ marginTop: '20px' }}>
                          Opciones
                        </Typography>
                        <Grid container direction='row' wrap='wrap'>
                          {Object.keys(inegre[tipo as 'ingresos']).map(
                            tipo1 => {
                              const aSaltar = ['Cobranza Por Proyecto', 'Obras']
                              return !aSaltar.includes(tipo1) ? (
                                <Grid item margin='1.5%'>
                                  <InputLabel id='tipo-m1'>{tipo1}</InputLabel>
                                  <AutoSelect
                                    multiple={true}
                                    freeSolo={true}
                                    data={
                                      inegre[tipo as 'ingresos'][tipo1].opciones
                                    }
                                    value={
                                      inegre[tipo as 'ingresos'][tipo1].opciones
                                    }
                                    setValue={(value: string[]) => {
                                      setOpciones(current => {
                                        return {
                                          ...current,
                                          [tipo as 'ingresos']: {
                                            ...current[tipo as 'ingresos'],
                                            [tipo1]: {
                                              ...current[tipo as 'ingresos'][
                                                tipo1
                                              ],
                                              opciones: value
                                            }
                                          }
                                        }
                                      })
                                    }}
                                  />
                                  {Object.keys(
                                    inegre[tipo as 'ingresos'][tipo1].nextLevel
                                  ).length > 0 && (
                                    <>
                                      <Divider
                                        sx={{
                                          color: 'black',
                                          marginTop: '20px',
                                          marginBottom: '-20px'
                                        }}
                                      >
                                        Opciones de {tipo1}
                                      </Divider>
                                      <Typography
                                        variant='h5'
                                        sx={{ marginTop: '20px' }}
                                      >
                                        Opciones 2
                                      </Typography>
                                      <Grid
                                        container
                                        direction='row'
                                        wrap='wrap'
                                      >
                                        {Object.keys(
                                          inegre[tipo as 'ingresos'][tipo1]
                                            .nextLevel
                                        ).map(tipo2 => {
                                          return (
                                            <Grid item margin='1.5%'>
                                              <InputLabel id='tipo-m1'>
                                                {tipo2}
                                              </InputLabel>
                                              <AutoSelect
                                                multiple={true}
                                                freeSolo={true}
                                                data={
                                                  inegre[tipo as 'ingresos'][
                                                    tipo1
                                                  ]['nextLevel'][tipo2].opciones
                                                }
                                                value={
                                                  inegre[tipo as 'ingresos'][
                                                    tipo1
                                                  ]['nextLevel'][tipo2].opciones
                                                }
                                                setValue={(value: string[]) => {
                                                  setOpciones(current => {
                                                    return {
                                                      ...current,
                                                      [tipo as 'ingresos']: {
                                                        ...current[
                                                          tipo as 'ingresos'
                                                        ],
                                                        [tipo1]: {
                                                          ...current[
                                                            tipo as 'ingresos'
                                                          ][tipo1],
                                                          nextLevel: {
                                                            ...current[
                                                              tipo as 'ingresos'
                                                            ][tipo1].nextLevel,
                                                            [tipo2]: {
                                                              ...current[
                                                                tipo as 'ingresos'
                                                              ][tipo1].nextLevel
                                                                .tipo2,
                                                              opciones: value
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  })
                                                }}
                                              />
                                            </Grid>
                                          )
                                        })}
                                      </Grid>
                                      <Divider
                                        sx={{
                                          color: 'black',
                                          marginTop: '0px',
                                          marginBottom: '5px'
                                        }}
                                      >
                                        Fin de Opciones de {tipo1}
                                      </Divider>
                                    </>
                                  )}
                                </Grid>
                              ) : null
                            }
                          )}
                        </Grid>
                      </Grid>
                    )
                  })}

                  <Divider />
                </Grid>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Guardar
                </Button>
              </form>
            </FormProvider>
          </Box>
        </Container>
        <Snackbar
          open={success}
          autoHideDuration={5000}
          onClose={handleClose}
          message='Operación Exitosa'
          action={action}
        />
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={handleClose}
          message='Ha ocurrido un error'
        />
      </Wrapper>
    </ThemeProvider>
  )
}

export default UpdateConfig
