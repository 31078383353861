import {
  Typography,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Container,
  Alert
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../theme'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { Credentials } from 'realm-web'
import { useRealm } from '../context/RealmContext'

const Signin = () => {
  const remember = useRef(false)
  const [error, setError] = useState(false)
  const { app } = useRealm()
  const navigate = useNavigate()
  const handleCheckBox = () => {
    remember.current = !remember.current
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const jsonData = {
      email: data.get('email') as string,
      password: data.get('password') as string
    }
    if (jsonData.email && jsonData.password) {
      const loginCredentials = Credentials.emailPassword(
        jsonData.email,
        jsonData.password
      )
      app
        ?.logIn(loginCredentials)
        .then(d => {
          console.log('🚀 ~ file: Signin.tsx:45 ~ .then ~ d:', d)
          navigate('/')
        })
        .catch(e => {
          console.error(e)
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 3000)
        })
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            variant='rounded'
            sx={{ m: 1, width: '40%', height: '40%', objectFit: 'contain' }}
            src='/assets/logo.png'
          />
          <Typography component='h1' variant='h5'>
            Bienvenido a Macutrol
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Correo'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Contraseña'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckBox}
                  value='remember'
                  color='primary'
                />
              }
              label='Recuerdame'
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href='#' variant='body2'>
                  Ayuda! olvidé la contraseña
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {error && (
          <Alert severity='error'>
            Parece que las credenciales son Incorrectas
          </Alert>
        )}
      </Container>
    </ThemeProvider>
  )
}

export default Signin
